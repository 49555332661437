var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-form',{ref:"socialAccountForm",staticClass:"form-section",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h3',[_vm._v("Job Sepecification")]),_c('div',{staticClass:"boxinside"},[_c('v-form',{ref:"companyBasicForm",staticClass:"form-section",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Eductaion")]),_c('v-select',{attrs:{"items":_vm.degreeses,"label":"Education","outlined":""},model:{value:(_vm.education),callback:function ($$v) {_vm.education=$$v},expression:"education"}})],1),_c('div',{staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Is this education criteria mandatory for applying a job?")]),_c('v-checkbox',{attrs:{"label":!_vm.isEducation
                ? `Candidate with any education degree can apply for this job`
                : `Above education level will be minimum education degree to apply for this job`},model:{value:(_vm.isEducation),callback:function ($$v) {_vm.isEducation=$$v},expression:"isEducation"}})],1),_c('div',{staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Experience (In Years)")]),_c('div',{staticClass:"removesection"},[_c('v-select',{staticClass:"spaceright",attrs:{"items":_vm.morethan,"label":"More Than","outlined":""},model:{value:(_vm.expericeMoreLess),callback:function ($$v) {_vm.expericeMoreLess=$$v},expression:"expericeMoreLess"}}),_c('v-text-field',{attrs:{"outlined":"","label":"Number of year"},model:{value:(_vm.expericeYear),callback:function ($$v) {_vm.expericeYear=$$v},expression:"expericeYear"}})],1)]),_c('div',{staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Is this experience criteria mandatory for applying a job?")]),_c('v-checkbox',{attrs:{"label":!_vm.isExperience
                ? `Candidate with any experience can apply for this job`
                : `Above experience will be minimum requirement to apply for this job`},model:{value:(_vm.isExperience),callback:function ($$v) {_vm.isExperience=$$v},expression:"isExperience"}})],1),_vm._l((_vm.skills),function(data,index){return _c('div',{key:index + 'sk',staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Skill")]),_c('div',{staticClass:"removesection"},[_c('v-text-field',{staticClass:"mr-btm-minus",attrs:{"outlined":"","label":"Skill"},model:{value:(data.skill),callback:function ($$v) {_vm.$set(data, "skill", $$v)},expression:"data.skill"}}),(index > 0)?_c('div',{staticClass:"iconbox removebutton",on:{"click":function($event){return _vm.removeSkill(index)}}},[_c('v-icon',[_vm._v(" mdi-minus-circle-outline ")]),_vm._v(" Remove ")],1):_vm._e()],1)])}),_c('v-btn',{staticClass:"addmore spacebottom",on:{"click":_vm.addSkill}},[_c('v-icon',[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Add Skill")],1),_c('div',{staticClass:"fullwidth"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Is this skill criteria mandatory for applying a job?")]),_c('v-checkbox',{attrs:{"label":!_vm.isSkill
                ? `Candidate with or without above skill can apply for this job`
                : `Above skill will be required to apply for this job`},model:{value:(_vm.isSkill),callback:function ($$v) {_vm.isSkill=$$v},expression:"isSkill"}})],1),_c('div',{staticClass:"fullwidth mr-btm"},[_c('label',{staticClass:"mr-btm"},[_vm._v("Applying Procedure")]),_c('quill-editor',{ref:"myQuillEditor",attrs:{"options":_vm.editorOption},on:{"blur":function($event){return _vm.onEditorBlur($event)},"focus":function($event){return _vm.onEditorFocus($event)},"ready":function($event){return _vm.onEditorReady($event)}},model:{value:(_vm.otherJobSpecification),callback:function ($$v) {_vm.otherJobSpecification=$$v},expression:"otherJobSpecification"}})],1),(!_vm.allloadingAd)?_c('v-btn',{staticClass:"addmore",on:{"click":_vm.saveJobSpecification}},[_vm._v("Next")]):_vm._e(),(_vm.allloadingAd)?_c('v-btn',{staticClass:"addmore"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_vm._e()],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }