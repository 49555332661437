<template>
  <section>
    <h3>Job Description</h3>
    <div class="boxinside">
      <v-form
        ref="socialAccountForm"
        v-model="valid"
        lazy-validation
        class="form-section"
      >
        <div class="fullwidth mr-btm">
          <label class="mr-btm">Requirements</label>
          <quill-editor
            ref="myQuillEditor"
            v-model="requirements"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>
        <div class="fullwidth mr-btm">
          <label class="mr-btm"> Job Responsibility</label>
          <quill-editor
            ref="myQuillEditor"
            v-model="careerObjectSummary"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>

        <div class="fullwidth mr-btm">
          <label class="mr-btm">Benefits</label>
          <quill-editor
            ref="myQuillEditor"
            v-model="benefits"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>
        <div class="fullwidth mr-btm">
          <label class="mr-btm">Who we are looking for </label>
          <quill-editor
            ref="myQuillEditor"
            v-model="whoWeareLookingFor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @ready="onEditorReady($event)"
          />
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Meta Title</label>
          <v-text-field
            v-model="metaTitle"
            outlined
            label="Meta Title"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Meta Keywords</label>
          <v-text-field
            v-model="metaKeywords"
            outlined
            label="Meta Keywords"
            class="mr-btm-minus"
          ></v-text-field>
        </div>
        <div class="fullwidth">
          <label class="mr-btm">Meta Description</label>
          <v-textarea
            v-model="metaDescription"
            outlined
            label="Meta Description"
            class="mr-btm-minus"
            rows="5"
          ></v-textarea>
        </div>
        <v-btn class="addmore" v-if="!allloadingAd" @click="saveJobDescription"
          >Save</v-btn
        >
        <v-btn class="addmore" v-if="allloadingAd"
          ><v-progress-circular indeterminate></v-progress-circular
        ></v-btn>
      </v-form>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "JobDescription",
  data: () => ({
    careerObjectSummary: "",
    benefits: "",
    requirements: "",
    whoWeareLookingFor: "",
    editorOption: {},
    valid: true,
    id: null,
    slug: "",
    metaTitle: "",
    metaKeywords: "",
    metaDescription: "",
  }),
  computed: {
    ...mapGetters([
      "allSingleJobDescription",
      "allSingleJobInformation",
      "allloadingAd",
    ]),
  },
  methods: {
    ...mapActions([
      "createJobDescription",
      "singleJobDescription",
      "singleJobInformationAdmin",
    ]),
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async saveJobDescription() {
      let jobDesc = {
        careerObjectSummary: this.careerObjectSummary,
        benefits: this.benefits,
        requirements: this.requirements,
        whoWeareLookingFor: this.whoWeareLookingFor,
        metaTitle: this.metaTitle,
        metaKeywords: this.metaKeywords,
        metaDescription: this.metaDescription,
      };
      if (this.$route.params.slug != undefined) {
        jobDesc.jobSlug = this.$route.params.slug;
      } else {
        jobDesc.jobSlug = this.allSingleJobInformation.results.slug;
      }
      if (this.id != null) {
        jobDesc.id = this.id;
      }
      await this.createJobDescription(jobDesc);
    },
  },
  async created() {
    if (this.$route.params.slug != undefined) {
      await this.singleJobDescription(this.$route.params.slug);
      this.careerObjectSummary =
        this.allSingleJobDescription.results.careerObjectSummary;
      this.benefits = this.allSingleJobDescription.results.benefits;
      this.requirements = this.allSingleJobDescription.results.requirements;
      this.whoWeareLookingFor =
        this.allSingleJobDescription.results.whoWeareLookingFor;
      this.id = this.allSingleJobDescription.results._id;
      this.slug = this.allSingleJobDescription.results.slug;
      this.metaTitle = this.allSingleJobDescription.results.metaTitle;
      this.metaKeywords = this.allSingleJobDescription.results.metaKeywords;
      this.metaDescription =
        this.allSingleJobDescription.results.metaDescription;
    } else if (this.$route.params.company != undefined) {
      await this.singleJobInformationAdmin(this.$route.params.company);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
