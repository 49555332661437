<template>
  <section>
    <v-row>
      <v-col md="3">
        <ul class="leftmenu">
          <li
            v-for="(data, index) in menulists"
            :key="index"
            :class="{ activeMenu: selected == data.slug }"
          >
            <div @click="chooseMenu(data.slug)">
              <v-icon class="icon">{{ data.icon }}</v-icon> {{ data.title }}
            </div>
          </li>
        
        </ul>
      </v-col>
      <v-col md="9">
        <v-row>
          <div v-if="selected == 'job_information'" class="fullwidth mainbox">
            <JobInformation @success="next"/>
          </div>
          <div v-if="selected == 'job_sepcification'" class="fullwidth mainbox">
            <JobSpecificaiton @success="next"/>
          </div>

          <div v-if="selected == 'job_description'" class="fullwidth mainbox">
            <JobDescription/>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import JobInformation from '@/components/Job/JobInformation'
import JobSpecificaiton from '@/components/Job/JobSpecificaiton'
import JobDescription from '@/components/Job/Jobdescription'
export default {
  name: "AddJob",
  components:{JobInformation,JobSpecificaiton,JobDescription},
  data: () => ({
    menulists: [
      {
        title: "Job Information",
        slug: "job_information",
        icon: "mdi-newspaper-variant-outline",
      },
      {
        title: "Job Specification",
        slug: "job_sepcification",
        icon: "mdi-school",
      },
      {
        title: "Job Description",
        slug: "job_description",
        icon: "mdi-share-variant",
      },
    ],
    benefit: "",
    requirement: "",
    whoWeAreLookingFor: "",
    about: "",
    selected: "job_information",
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    checkbox: false,
    isEducation: false,
    isExperience: false,
    isSkill: false,
    otherJobSpecification: "",
    socialLinks: [{ accountName: "", accontLink: "" }],
    contactPersons: [
      {
        name: "",
        email: "",
        designation: "",
        phone1: "",
        phon2: "",
        phone3: "",
      },
    ],
    contactNumbers: [{ phone: "" }],
    hasImage: false,
    image: null,
    msg: "Vue Image Upload and Resize Demo",
    jobLocations: [{ location: "" }],
    subscriptionTypes: [
      "Premium Job",
      "Standard Job",
      "Featured Job",
      "Free Job",
    ],
    jobTypes: ["Full Time", "Hourly", "Daily", "Yearly"],
    jobLevels: ["Expert", "Intermediate"],
  }),
  methods: {
    chooseMenu(data) {
      this.selected = data;
    },
    addSocial() {
      this.socialLinks.push({ accountName: "", accontLink: "" });
    },
    removeSocial(index) {
      this.socialLinks.splice(index, 1);
    },
    addContactPerson() {
      this.contactPersons.push({
        name: "",
        email: "",
        designation: "",
        phone1: "",
        phon2: "",
        phone3: "",
      });
    },
    removeContactPerson(index) {
      this.contactPersons.splice(index, 1);
    },
    addContactNumber() {
      this.contactNumbers.push({
        phone: "",
      });
    },
    removeContactNumber(index) {
      this.contactNumbers.splice(index, 1);
    },
    setImage: function(output) {
      this.hasImage = true;
      this.image = output;
    },
    addJobLocation() {
      this.jobLocations.push({ location: "" });
    },
    removeJobLocation(index) {
      this.jobLocations.splice(index, 1);
    },
    next(e){
      // console.log("hey")
      this.chooseMenu(e);
    }
    
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
ul{
  list-style: none;
}
</style>
