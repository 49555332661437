<template>
  <section>
    <v-form
      ref="socialAccountForm"
      v-model="valid"
      lazy-validation
      class="form-section"
    >
      <h3>Job Sepecification</h3>
      <div class="boxinside">
        <!-- {{allSingleJobInformation}} -->
        <v-form
          ref="companyBasicForm"
          v-model="valid"
          lazy-validation
          class="form-section"
        >
          <div class="fullwidth">
            <label class="mr-btm">Eductaion</label>
            <v-select
              v-model="education"
              :items="degreeses"
              label="Education"
              outlined
            ></v-select>
          </div>

          <div class="fullwidth">
            <label class="mr-btm"
              >Is this education criteria mandatory for applying a job?</label
            >
            <v-checkbox
              v-model="isEducation"
              :label="
                !isEducation
                  ? `Candidate with any education degree can apply for this job`
                  : `Above education level will be minimum education degree to apply for this job`
              "
            ></v-checkbox>
          </div>
          <div class="fullwidth">
            <label class="mr-btm">Experience (In Years)</label>
            <div class="removesection">
              <v-select
                v-model="expericeMoreLess"
                :items="morethan"
                label="More Than"
                class="spaceright"
                outlined
              ></v-select>
              <v-text-field
                v-model="expericeYear"
                outlined
                label="Number of year"
              >
              </v-text-field>
            </div>
          </div>
          <div class="fullwidth">
            <label class="mr-btm"
              >Is this experience criteria mandatory for applying a job?</label
            >
            <v-checkbox
              v-model="isExperience"
              :label="
                !isExperience
                  ? `Candidate with any experience can apply for this job`
                  : `Above experience will be minimum requirement to apply for this job`
              "
            ></v-checkbox>
          </div>

          <div
            class="fullwidth"
            v-for="(data, index) in skills"
            :key="index + 'sk'"
          >
            <label class="mr-btm">Skill</label>
            <div class="removesection">
              <v-text-field
                v-model="data.skill"
                outlined
                label="Skill"
                class="mr-btm-minus"
              ></v-text-field>
              <div
                class="iconbox removebutton"
                @click="removeSkill(index)"
                v-if="index > 0"
              >
                <v-icon> mdi-minus-circle-outline </v-icon> Remove
              </div>
            </div>
          </div>

          <v-btn class="addmore spacebottom" @click="addSkill"
            ><v-icon> mdi-plus-circle-outline </v-icon> Add Skill</v-btn
          >

          <div class="fullwidth">
            <label class="mr-btm"
              >Is this skill criteria mandatory for applying a job?</label
            >
            <v-checkbox
              v-model="isSkill"
              :label="
                !isSkill
                  ? `Candidate with or without above skill can apply for this job`
                  : `Above skill will be required to apply for this job`
              "
            ></v-checkbox>
          </div>
          <div class="fullwidth mr-btm">
            <label class="mr-btm">Applying Procedure</label>
            <quill-editor
              ref="myQuillEditor"
              v-model="otherJobSpecification"
              :options="editorOption"
              @blur="onEditorBlur($event)"
              @focus="onEditorFocus($event)"
              @ready="onEditorReady($event)"
            />
          </div>
          <v-btn
            class="addmore"
            v-if="!allloadingAd"
            @click="saveJobSpecification"
            >Next</v-btn
          >
          <v-btn class="addmore" v-if="allloadingAd"
            ><v-progress-circular indeterminate></v-progress-circular
          ></v-btn>
        </v-form>
      </div>
    </v-form>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "JobSepcification",
  data: () => ({
    valid: true,
    msg: "Vue Image Upload and Resize Demo",
    jobLocations: [{ location: "" }],
    subscriptionTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    editorOption: {},
    otherJobSpecification: "",
    jobTypes: ["Full Time", "Hourly", "Daily", "Yearly"],
    jobLevels: ["Expert", "Intermediate"],
    degreeses: ["SLC", "10+2", "Bachelor", "Master", "PHD"],
    morethan: [
      "More Then",
      "Less Then",
      "Exactly",
      "More Then Or Equals To",
      "Less Then Or Equals To",
    ],
    isEducation: false,
    isSkill: false,
    education: "",
    expericeMoreLess: "",
    expericeYear: "",
    id: null,
    isExperience: false,
    skills: [{ skill: "" }],
  }),
  methods: {
    ...mapActions([
      "createJobSpecification",
      "singleJobSpecification",
      "singleJobInformationAdmin",
    ]),
    addSkill() {
      this.skills.push({ skill: "" });
    },
    removeSkill(index) {
      this.skills.splice(index, 1);
    },
    addJobLocation() {
      this.jobLocations.push({ location: "" });
    },
    removeJobLocation(index) {
      this.jobLocations.splice(index, 1);
    },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async saveJobSpecification() {
      let jobspec = {
        education: this.education,
        agreeEducation: this.isEducation,
        experience: {
          expericeMoreLess: this.expericeMoreLess,
          expericeYear: this.expericeYear,
        },
        agreeExperience: this.isExperience,
        skills: this.skills,
        agreeSkill: this.isSkill,
        otherSpecification: this.otherJobSpecification,
      };
      if (this.$route.params.slug != undefined) {
        jobspec.jobSlug = this.$route.params.slug;
      } else {
        jobspec.jobSlug = this.allSingleJobInformation.results.slug;
      }
      if (this.id != null) {
        jobspec.id = this.id;
      }
      // console.log(jobspec);
      await this.createJobSpecification(jobspec);
      if (!this.allloadingAd) {
        this.$emit("success", "job_description");
      }
    },
  },
  computed: {
    ...mapGetters([
      "allSingleJobSecification",
      "allSingleJobInformation",
      "allloadingAd",
    ]),
  },
  async created() {
    console.log("hey");
    if (this.$route.params.slug != undefined) {
      await this.singleJobSpecification(this.$route.params.slug);
      this.education = this.allSingleJobSecification.results.education;
      this.isEducation = this.allSingleJobSecification.results.agreeEducation;
      this.expericeMoreLess =
        this.allSingleJobSecification.results.experience.expericeMoreLess;
      this.expericeYear =
        this.allSingleJobSecification.results.experience.expericeYear;
      this.isExperience = this.allSingleJobSecification.results.agreeExperience;
      this.agreeSkill = this.allSingleJobSecification.results.agreeSkill;
      this.otherJobSpecification =
        this.allSingleJobSecification.results.otherSpecification;
      this.skills = [];
      this.allSingleJobSecification.results.skills.forEach((ele) => {
        this.skills.push({ skill: ele.skill });
      });
      this.id = this.allSingleJobSecification.results._id;
    } else if (this.$route.params.company != undefined) {
      await this.singleJobInformationAdmin(this.$route.params.company);
    }

    console.log(this.allSingleJobSecification.results);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/form.scss";
</style>
